import { useEffect, useState } from "react";
import "./App.css";
import ReactSlider from "react-slider";
import { motion, AnimatePresence } from "framer-motion";


var JsonData = require('./data/flavors.json')

function App() {

  const [selectedFlavor, setSelectedFlavor] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [hoveredKey, setHoveredKey] = useState(false);

  const handleItemClick = (key) => {
    setSelectedFlavor(key);
  };

  const [paragraphs, setParagraphs] = useState(3);
  const [copied, setCopied] = useState(false);
  const handleParagraphsChange = (value) => {
    setParagraphs(value);
    console.log('value : ', value);
  };

  const handleCopyText = () => {
    const textToCopy = JsonData[selectedFlavor].paragraphs.slice(0, paragraphs).join("\n");
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard!");
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2500);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  useEffect(() => {
  }, [selectedFlavor]);

  return (
    <div style={{ background: JsonData[selectedFlavor].color }} className=" transition-all duration-200 ease-in-out overflow-x-clip">

      <div className="min-h-screen h-full w-full flex flex-col lg:flex-row gap-10 lg:gap-24 max-w-screen-xl mx-auto px-10 font-space-grotesk text-boba-black ">


        {/* ------ Settings ------ */}
        <div style={{ background: JsonData[selectedFlavor].color }} className="z-10 border-[1px] border-boba-black py-4 w-full max-w-full lg:max-w-[208px] h-fit static lg:sticky mt-10 lg:mt-0 top-10 ">
          <div className="p-4 pt-0 flex justify-center flex-col">
            <h1 className="font-space-mono text-xl">boba ipsum</h1>
            <h4 className="text-[10px]">by <a href="https://halfsugarlessice.com" target="_blank" className="underline">half sugar less ice</a></h4>
          </div>
          <div className="border-y-[1px] border-boba-black p-4 flex flex-row justify-between w-full lg:flex-col gap-3">
            <h2>flavor</h2>
            <div className="flex gap-4 flex-wrap w-44 lg:w-full justify-between lg:justify-normal lg:gap-4">
              {
                JsonData.map((flavor, key) => (
                  <button key={key} className={`${selectedFlavor === key ? 'border-[6px]' : 'border-[1px]'} border-boba-black rounded-full h-[32px] w-[32px] lg:h-[22px] lg:w-[22px] transition-border duration-200 ease-in-out relative z-10`}
                    style={{ background: flavor.color }}
                    onClick={() => {
                      handleItemClick(key);
                      setHovered(false);
                    }}
                    onMouseEnter={() => {
                      setHovered(true)
                      setHoveredKey(key)
                    }}
                    onMouseLeave={() => {
                      setHovered(false)
                    }}
                  >
                    {
                      hovered &&
                      <AnimatePresence>
                        {
                          hoveredKey === key &&
                          <motion.div initial={{ opacity: 0, y: -12 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -12 }} className={`absolute -bottom-11 text-xs -left-[22px] text-white h-[35px] w-[65px] bg-boba-black rounded-[10px] hidden lg:flex items-center text-center justify-center z-[9] ${hoveredKey === selectedFlavor && '-bottom-12 -left-[26px]'} `}>
                            <span className="arrow-up absolute -top-2"></span>
                            {flavor.name}
                          </motion.div>
                        }
                      </AnimatePresence>
                    }
                  </button>
                ))
              }

            </div>
          </div>
          <div className="border-b-[1px] border-boba-black p-4 flex flex-row justify-between lg:justify-normal lg:flex-col gap-3">
            <h2>paragraphs</h2>
            <div className="flex gap-4 w-44 lg:w-full h-[32px] lg:h-[22px] rounded-full border-[1px] border-boba-black relative">
              <div className="rounded-full h-full w-[30px] lg:w-[20px] border-[1px] border-l-0 border-boba-black absolute top-0 left-0"></div>
              <ReactSlider
                min={1}
                max={8}
                defaultValue={paragraphs}
                ariaLabelledby="slider-label"
                className="horizontal-slider w-full h-[32px] lg:h-[23px] -top-[0px] lg:top-0"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                renderThumb={(props, state) => <div {...props}>
                  {state.valueNow}
                </div>}
                onChange={handleParagraphsChange}
              />

            </div>
          </div>
          <div className="p-4 pb-0 flex flex-col gap-3">
            <button className="py-3 px-2 h-full- w-full bg-boba-black text-[#FDF1E3] rounded-[10px] transition-all duration-200 ease-in-out"
              onClick={() => handleCopyText()}
              disabled={copied}
            >

              {copied ?
                <AnimatePresence>
                  <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex gap-1 items-center justify-center w-full">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.55 17.9996L3.85 12.2996L5.275 10.8746L9.55 15.1496L18.725 5.97461L20.15 7.39961L9.55 17.9996Z" fill="white" />
                    </svg>
                    copied
                  </motion.span>
                </AnimatePresence>
                :
                <AnimatePresence >
                  <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex gap-1 items-center justify-center w-full">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 11.9997C5.63333 11.9997 5.31944 11.8691 5.05833 11.608C4.79722 11.3469 4.66667 11.033 4.66667 10.6663V2.66634C4.66667 2.29967 4.79722 1.98579 5.05833 1.72467C5.31944 1.46356 5.63333 1.33301 6 1.33301H12C12.3667 1.33301 12.6806 1.46356 12.9417 1.72467C13.2028 1.98579 13.3333 2.29967 13.3333 2.66634V10.6663C13.3333 11.033 13.2028 11.3469 12.9417 11.608C12.6806 11.8691 12.3667 11.9997 12 11.9997H6ZM6 10.6663H12V2.66634H6V10.6663ZM3.33333 14.6663C2.96667 14.6663 2.65278 14.5358 2.39167 14.2747C2.13056 14.0136 2 13.6997 2 13.333V3.99967H3.33333V13.333H10.6667V14.6663H3.33333Z" fill="#FDF1E3" />
                    </svg>

                    copy text
                  </motion.span>
                </AnimatePresence>
              }
            </button>
          </div>
        </div>

        {/* --- Paragraph Rendering ---- */}
        <div className=" flex flex-col items-center py-4 lg:py-10">
          <div className="w-full max-w-full lg:max-w-screen-sm flex flex-col gap-5 ">
            <AnimatePresence mode="sync">
              {JsonData[selectedFlavor].paragraphs.slice(0, paragraphs).map((paragraph, index) => (
                <motion.p className="text-[22px] leading-[28.07px]" key={index}
                  initial={{ opacity: 0, y: 12 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -12 }}
                  transition={{ duration: 0.25, delay: 0.05 * index }}
                >
                  {paragraph}
                </motion.p>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
